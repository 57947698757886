.solid-button.purple {
    background: #7c4dff;
    color: #fff;
  }
  .solid-button.white {
    background: #fff;
    color: #232323;
  }
  
  .main-content {
    padding-top: 13rem;
  }
  .section-img {
    max-width: 100%;
    max-height: 100%;
  }
  
  @media all and (max-width: 768px) {
    .main-content {
      padding-top: 1rem;
    }
  }
  
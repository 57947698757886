.about-me {
  padding: 72px 12px;
}

/* ==========================================================================
      12. Services
      ========================================================================== */
.service {
  background: #fff;
  text-align: center;
  padding: 48px 0;
  color: #232323;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -o-transition: 0.25s;
  -ms-transition: 0.25s;
  transition: 0.25s;
  margin-bottom: 24px;
}
.service:hover {
  background: #7c4dff;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
.service .icon {
  font-size: 72px;
  line-height: 72px;
  margin-bottom: 24px;
}
.service h5 {
  margin-bottom: 0;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -o-transition: 0.25s;
  -ms-transition: 0.25s;
  transition: 0.25s;
}
.service:hover h5 {
  color: #fff;
}

/* ==========================================================================
      11. Signature
      ========================================================================== */
.signature {
  border-bottom: 2px dashed #232323;
  position: relative;
  margin: 0 18px;
  padding-bottom: 12px;
  margin-bottom: 24px;
}
.signature:before {
  display: block;
  content: "";
  width: 14px;
  height: 14px;
  background: #fff;
  border: 4px solid #7c4dff;
  border-radius: 100%;
  position: absolute;
  left: -18px;
  bottom: -7px;
}
.signature:after {
  display: block;
  content: "";
  width: 14px;
  height: 14px;
  background: #fff;
  border: 4px solid #7c4dff;
  border-radius: 100%;
  position: absolute;
  right: -18px;
  bottom: -7px;
}

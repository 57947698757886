.exp {
  padding: 72px 12px;
}
/* ==========================================================================
      13. Experience
      ========================================================================== */
.experience-block {
  position: relative;
  padding-bottom: 30px;
}
.experience-block .icon {
  display: block;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
  background: #fff;
  border: 5px solid #e5e5e5;
  border-radius: 100%;
  font-size: 36px;
  color: #000;
  position: absolute;
  top: 0;
  left: 30%;
  margin-left: -39px;
  z-index: 2;
}
.experience-block .line {
  display: block;
  height: 100%;
  width: 2px;
  border-left: 2px dashed #4d4d4d;
  position: absolute;
  top: 0;
  left: 30%;
}
.experience-block .meta {
  width: 20%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  float: left;
  margin-top: 39px;
}
.experience-block .meta:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 10px 0 0;
  border-color: #7c4dff transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -10px;
}
.experience-block .meta .year {
  display: block;
  background: #7c4dff;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 700;
}
.experience-block .meta .company {
  display: block;
  background: #fff;
  color: #232323;
  text-align: center;
  font-size: 15px;
  line-height: 50px;
  text-transform: uppercase;
  font-weight: 500;
}
.experience-block .content {
  width: 60%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: relative;
  padding: 20px 30px;
  float: right;
  margin-top: 39px;
}
.experience-block .content:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 13px 0;
  border-color: transparent #fff transparent transparent;
  position: absolute;
  top: 0;
  left: -10px;
}
.experience-block .content h5 {
  margin-bottom: 6px;
}
.experience-block .content p:last-of-type {
  margin-bottom: 0;
}
@media all and (max-width: 768px) {
  .experience-block .icon {
    left: 0;
    margin-left: 0;
  }
  .experience-block .line {
    left: 38px;
  }
  .experience-block .meta {
    width: 60%;
    float: right;
    margin-top: 12px;
  }
  .experience-block .meta:after {
    border-width: 0 10px 13px 0;
    border-color: transparent #7c4dff transparent transparent;
    right: auto;
    left: -10px;
  }
  .experience-block .content {
    margin-top: 12px;
    width: 80%;
    padding: 10px 20px;
  }
  .experience-block .content:after {
    border-width: 10px 0 0 13px;
    border-color: transparent transparent transparent #fff;
    top: -10px;
    left: 0;
  }
}

/* ==========================================================================
      14. Testimonials
      ========================================================================== */
.testimonial-slider {
  text-align: center;
  margin-top: 24px;
  height: 22rem;
}

.testimonial-slider .carousel-root {
  height: inherit;
  .author {
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 32px;
    display: inline-block;
  }
  .control-dots .dot {
    background: #7c4dff;
    border-radius: 0%;
    width: 30px;
    height: 4px;
  }
  .carousel-slider {
    height: inherit;
    .slider-wrapper {
      height: inherit;
      .slide {
        .legend {
          transition: none;
          top: 175px;
          bottom: unset;
          background: none;
          color: black;
          p {
            font-size: 18px;
            line-height: 28px;
            font-style: italic;
          }
        }
      }
    }
  }
}

.circular-image {
  width: 115px !important;
  height: 115px;
  border: 5px solid #fff;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  margin-top: 2px;
  margin-bottom: 20px;
}
